import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pagetitle"

const WillsAndEstates = () => (
  <Layout>
    <PageTitle title="Wills And Estate Planning" page="PageTitleWill" />
    <Seo title="Wills and Estate Planning in Annapolis MD" description="With more than 10 years’ experience helping people resolve all of their estate matters, The Law Office of Sean E Becker, LLC is well-versed in the state laws and knows how to find smart and effective solutions. " />
    <div className="content-page-wrapper">
        <div className="page-intro">
            <p>
            Estate planning and creating an effective will, which achieves all your intended goals, require a tremendous amount of skill. That's where an experienced Annapolis estate attorney Sean Becker can help. With more than 10 years’ experience helping people resolve all of their estate matters, The Law Office of Sean E Becker, LLC is well-versed in the state laws and knows how to find smart and effective solutions. Don't hesitate to call us today to arrange a time to discuss creating or amending your existing will or trust. 
            </p>            
        </div> 
    </div>        
  </Layout>
)

export default WillsAndEstates
